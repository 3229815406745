import {
  DeliveryPointWithFullAddress,
  LocationPoint,
} from '@omnic/widget-locations';

import { Key } from '@src/types/keys';

export function getSessionStorageData() {
  const savedLocationPoint = sessionStorage.getItem(Key.locationPoint);
  const savedDeliveryAddress = sessionStorage.getItem(Key.deliveryAddress);

  return {
    savedLocationPoint: savedLocationPoint
      ? (JSON.parse(savedLocationPoint) as LocationPoint)
      : undefined,
    savedDeliveryAddress: savedDeliveryAddress
      ? (JSON.parse(savedDeliveryAddress) as DeliveryPointWithFullAddress)
      : undefined,
  };
}
