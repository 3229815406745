import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { TrackingData } from '../types';

export function useGetTrackingData() {
  const { request } = useApiContext();

  return useCallback(
    async (trackingUid: string): Promise<TrackingData> => {
      const { data } = await request<TrackingData>({
        url: `/public/order/info?onc=${trackingUid}`,
      });

      return data;
    },
    [request],
  );
}
