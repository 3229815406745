import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Message,
  PageWrapper,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import ActionMessage from '@src/components/ActionMessage';
import { useTrackingContext } from '@src/contexts/TrackingContext';
import { Routes } from '@src/types/routes';
import { openTracker } from '@src/utils/openTracking';

import { PageData, Result } from './types';

import { PointInfoWrapper } from '../../styled';
import { ContentPaper } from './styled';

export default function ConfirmationResult(): JSX.Element {
  const { result } = useParams() as {
    result: Result;
  };

  const { locationPoint, deliveryAddress, trackingData, flow } =
    useTrackingContext();

  const deliveryData = locationPoint || deliveryAddress;
  const locationBrand = locationPoint?.brand;
  const locationName = locationPoint?.name;
  const point =
    locationBrand && locationName ? `${locationBrand} ${locationName}` : '';
  const address =
    locationPoint?.location.full_address ||
    deliveryAddress?.address?.address ||
    '';

  const { t } = useTranslations();
  const navigate = useQueryNavigate();

  useLayoutEffect(() => {
    if (!deliveryData || flow !== 'deliveryPoint') {
      navigate(Routes.main);
    }
  }, [deliveryData, flow, navigate]);

  function trackOrder() {
    if (!trackingData) return;
    openTracker(trackingData);
  }

  const { iconName, title, subtitle, elements }: PageData =
    result === 'success'
      ? {
          iconName: 'success',
          title: t('success'),
          subtitle: t('yourPointOfDelivery'),
          elements: (
            <PointInfoWrapper>
              {point && (
                <ActionMessage
                  title={t('parcelLocker')}
                  text={point}
                  action="copy"
                />
              )}
              <ActionMessage title={t('address')} text={address} />
            </PointInfoWrapper>
          ),
        }
      : {
          iconName: 'error',
          title: t('somethingWentWrong'),
          subtitle: t('chatBotHelp'),
        };

  return (
    <PageWrapper>
      <ContentPaper $allAvailableHeight>
        <Message
          iconConfig={{ name: iconName }}
          title={title}
          subtitle={subtitle}
        />
        {elements}
      </ContentPaper>
      {result === 'success' && (
        <Button
          label={t('trackYourOrder')}
          variant="contained"
          onClick={trackOrder}
        />
      )}
    </PageWrapper>
  );
}
