import {
  DeliveryPointWithFullAddress,
  LocationPoint,
} from '@omnic/widget-locations';
import {
  CountrySettings,
  NetworkSettings,
  RequestStatus,
} from '@omnipkg/ui-kit-web';

export enum PointType {
  STORAGE = 10,
  DELIVERY_POINT = 20,
  PARCEL_LOCKER = 30,
  ADDRESS = 40,
}

export interface TrackingData {
  address_selection?: boolean;
  point_type_from: PointType;
  point_type_to: PointType;
  address_from: string;
  address_to: string;
  cod_value: number;
  created_at: string;
  declared_cost: number;
  delivery_cost: number | null;
  delivery_date: string | null;
  description: string | null;
  expiration_date: string | null;
  height: number;
  length: number;
  location_widget_inn: string;
  logist_company_id: number;
  logist_number: string;
  logs: [];
  modified_at: string;
  order_number: string;
  order_status: number;
  point_brand_from: string | null;
  point_brand_to: string | null;
  point_code_from: string;
  point_code_to: string;
  point_latitude_to: number;
  point_longitude_to: number;
  point_name_from: string | null;
  point_name_to: string | null;
  receiver_email: string | null;
  receiver_name: string;
  receiver_phone: string;
  sender_email: string | null;
  sender_name: string;
  sender_phone: string;
  uid: string;
  vendor_company_id: number;
  vendor_name: string;
  vendor_number: string;
  vendor_uid: string;
  weight: number;
  width: number;
}

export interface SiteSettings {
  country_settings: CountrySettings;
  network_settings: NetworkSettings;
}

export type Flow = 'orderTracker' | 'deliveryPoint';

export interface TrackingContextValue {
  locationPoint?: LocationPoint;
  deliveryAddress?: DeliveryPointWithFullAddress;
  flow?: Flow;
  trackingUid: string | null;
  trackingData?: TrackingData;
  siteSettings?: SiteSettings;
  trackingStatus: RequestStatus;
  clearPointData: () => void;
  recordLocationPoint: (point: LocationPoint) => void;
  recordDeliveryAddress: (address: DeliveryPointWithFullAddress) => void;
}
