import { useMemo } from 'react';
import { Widget } from '@omnic/widget-locations';
import { useTranslations } from '@omnipkg/ui-kit-web';
import { useTheme } from 'styled-components';

import { INITIAL_ZOOM_MAP, WIDGET_BASE_URL } from '@src/constants/common';
import { useTrackingContext } from '@src/contexts/TrackingContext';
import { TrackingData } from '@src/contexts/TrackingContext/types';

export default function Map(): JSX.Element {
  const { trackingData: data } = useTrackingContext();
  const { palette } = useTheme();
  const { langData } = useTranslations();

  const trackingData = data as TrackingData;

  const primaryColor = palette.primary.main;
  const secondaryColor = palette.grey[100];
  const latitude = Number(trackingData.point_latitude_to);
  const longitude = Number(trackingData.point_longitude_to);
  const center = useMemo(() => [latitude, longitude], [latitude, longitude]);

  return (
    <div id="@omnic/widget-locations">
      <Widget
        config={{
          startScreen: 'map',
          hideLogo: true,
          INN: trackingData.location_widget_inn,
          locale: langData.lang,
          palette: {
            primary: primaryColor,
            'primary-dark': secondaryColor,
          },
          map: {
            initialZoom: INITIAL_ZOOM_MAP,
            provider: 'google',
            center,
          },
          disableGeolocation: true,
          disableDelivery: true,
          radius: 2,
          font: 'Roboto',
        }}
        options={{
          baseURL: `${WIDGET_BASE_URL}/type-1/public/`,
        }}
      />
    </div>
  );
}
