import {
  SVGIcon,
  useNotifications,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import { Routes } from '@src/types/routes';

import { ActionMessageProps } from './types';

import { ActionButton, Container, MessageWrapper, Text, Title } from './styled';

export default function ActionMessage({
  title,
  text,
  action,
}: ActionMessageProps) {
  const { t } = useTranslations();
  const { setNotification } = useNotifications();
  const navigate = useQueryNavigate();

  function openMap() {
    navigate(Routes.map);
  }

  function copy() {
    navigator.clipboard.writeText(text);
    setNotification({
      status: 'success',
      value: t('textCopied'),
    });
  }

  function setButton() {
    switch (action) {
      case 'copy':
        return (
          <ActionButton onClick={copy}>
            <SVGIcon name="copy" />
          </ActionButton>
        );
      case 'openMap':
        return (
          <ActionButton onClick={openMap}>
            <SVGIcon name="map" />
          </ActionButton>
        );
      default:
        return null;
    }
  }

  return (
    <Container>
      <MessageWrapper>
        <Title>{title}:</Title>
        <Text>{text}</Text>
      </MessageWrapper>
      {setButton()}
    </Container>
  );
}
