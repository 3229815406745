import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { SiteSettings } from '../types';

export function useSiteSettings() {
  const { request } = useApiContext();

  return useCallback(
    async (locationWidgetINN: string): Promise<SiteSettings> => {
      const { data } = await request<SiteSettings>({
        baseURL: process.env.HUB_API,
        url: `/public/network/${locationWidgetINN}/settings/`,
      });

      return data;
    },
    [request],
  );
}
