import { useEffect } from 'react';

import { useTrackingContext } from '@src/contexts/TrackingContext';

export function useZendeskWidget() {
  const { siteSettings } = useTrackingContext();

  const isChatBotEnabled = siteSettings?.network_settings.chat_bot;

  useEffect(() => {
    if (!isChatBotEnabled) return;

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=761921e9-8a9a-49da-9b66-46b1c3179133';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [isChatBotEnabled]);
}
