import { useLocation, useMatch } from 'react-router-dom';

import { Routes } from '@src/types/routes';

interface HookReturns {
  isHideNavigation: boolean;
}

export function useHeaderNavigation(): HookReturns {
  const { pathname } = useLocation();
  const isErrorPage = pathname.includes('error');
  const isSearchPage = pathname.includes('success');
  const isMainPage = !!useMatch(Routes.main);

  const isHideNavigation = isErrorPage || isSearchPage || isMainPage;

  return {
    isHideNavigation,
  };
}
