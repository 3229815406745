import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import {
  DeliveryPointWithFullAddress,
  LocationPoint,
} from '@omnic/widget-locations';
import {
  ContextProps,
  ErrorType,
  OrderStatus,
  RequestStatus,
  useQueryNavigate,
} from '@omnipkg/ui-kit-web';

import { Key } from '@src/types/keys';
import { Routes } from '@src/types/routes';
import { getSessionStorageData } from '@src/utils/getSessionStorageData';

import {
  Flow,
  SiteSettings,
  TrackingContextValue,
  TrackingData,
} from './types';
import { useSiteSettings } from './utils/getSiteSettings';
import { useGetTrackingData } from './utils/getTrackingData';

const INN = process.env.INN as string;

const TrackingContext = createContext<TrackingContextValue | null>(null);

export function TrackingContextProvider({
  children,
}: ContextProps): JSX.Element {
  const { savedLocationPoint, savedDeliveryAddress } = useMemo(
    () => getSessionStorageData(),
    [],
  );

  const [locationPoint, setLocationPoint] = useState<LocationPoint | undefined>(
    savedLocationPoint,
  );
  const [deliveryAddress, setDeliveryAddress] = useState<
    DeliveryPointWithFullAddress | undefined
  >(savedDeliveryAddress);
  const [flow, setFlow] = useState<Flow>();
  const [siteSettings, setSiteSettings] = useState<SiteSettings>();
  const [trackingData, setTrackingData] = useState<TrackingData>();
  const [trackingStatus, setTrackingStatus] = useState<RequestStatus>('idle');
  const [searchParams] = useSearchParams();

  const trackingUid = searchParams.get('uid');
  const isErrorPage = !!useMatch(Routes.error);

  const navigate = useQueryNavigate();

  const getTrackingData = useGetTrackingData();
  const getSiteSettings = useSiteSettings();

  const recordLocationPoint = useCallback((data: LocationPoint) => {
    sessionStorage.setItem(Key.locationPoint, JSON.stringify(data));
    setLocationPoint(data);
  }, []);

  const recordDeliveryAddress = useCallback(
    (data: DeliveryPointWithFullAddress) => {
      sessionStorage.setItem(Key.deliveryAddress, JSON.stringify(data));
      setDeliveryAddress(data);
    },
    [],
  );

  const clearPointData = useCallback(() => {
    sessionStorage.removeItem(Key.locationPoint);
    sessionStorage.removeItem(Key.deliveryAddress);
    setLocationPoint(undefined);
    setDeliveryAddress(undefined);
  }, []);

  useEffect(() => {
    if (!trackingUid) {
      setTrackingStatus('error');
      navigate(Routes.error, {
        state: { errorType: ErrorType.orderDoesNotExist },
      });
      return;
    }

    if (trackingData) return;

    setTrackingStatus('pending');

    getTrackingData(trackingUid)
      .then(async (data) => {
        setTrackingData(data);

        const settings = await getSiteSettings(INN);
        setSiteSettings(settings);

        if (data.order_status === OrderStatus.WITHDRAWN_BY_COURIER) {
          setFlow('deliveryPoint');
        } else {
          setFlow('orderTracker');
        }

        setTrackingStatus('success');

        if (isErrorPage) {
          navigate(Routes.main);
        }
      })
      .catch(() => {
        setTrackingStatus('error');
        navigate(Routes.error);
      });
  }, [
    trackingUid,
    trackingData,
    isErrorPage,
    getTrackingData,
    getSiteSettings,
    navigate,
  ]);

  const value: TrackingContextValue = useMemo(
    () => ({
      flow,
      locationPoint,
      deliveryAddress,
      trackingUid,
      trackingData,
      siteSettings,
      trackingStatus,
      clearPointData,
      recordLocationPoint,
      recordDeliveryAddress,
    }),
    [
      locationPoint,
      deliveryAddress,
      trackingUid,
      trackingData,
      trackingStatus,
      siteSettings,
      flow,
      clearPointData,
      recordLocationPoint,
      recordDeliveryAddress,
    ],
  );

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
}

export function useTrackingContext(): TrackingContextValue {
  const value = useContext(TrackingContext);

  if (value) return value;

  throw new Error(
    'Please, use useTrackingContext hook inside TrackingContextProvider',
  );
}
