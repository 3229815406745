import styled from 'styled-components';

import { Txt1Bold, Txt2 } from '@src/theme/common';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }): string => theme.spacing(2)};
  border: 1px solid ${({ theme }): string => theme.palette.primary.main};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(2)} ${spacing(2.5)}`};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const MessageTitle = styled.h2`
  ${Txt1Bold};
  margin-bottom: ${({ theme }): string => theme.spacing()};
`;

export const MessageText = styled.span`
  ${Txt2};
`;
