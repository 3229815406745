import { useCallback } from 'react';
import { DeliveryPointWithFullAddress } from '@omnic/widget-locations';
import { useApiContext } from '@omnipkg/ui-kit-web';

type Point =
  | {
      code: string;
      brand: string;
    }
  | {
      address: DeliveryPointWithFullAddress['address'];
    };

export type ConfirmPointArgs = {
  orderUid: string;
  trackingUid: string;
} & Point;

interface ConfirmPointRequest {
  uid: string;
  point: Point;
}

export function useConfirmPoint() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      trackingUid,
      orderUid,
      ...data
    }: ConfirmPointArgs): Promise<void> => {
      await request<never, ConfirmPointRequest>({
        method: 'POST',
        url: `/public/order/pickup-point/assign?uid=${trackingUid}`,
        data: {
          uid: orderUid,
          point: data,
        },
      });
    },
    [request],
  );
}
