import { useMemo } from 'react';
import { Widget } from '@omnic/widget-locations';
import {
  PageWrapper,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';
import { useTheme } from 'styled-components';

import Footer from '@src/components/Footer';
import InfoMessage from '@src/components/InfoMessage';
import { INITIAL_ZOOM_MAP, WIDGET_BASE_URL } from '@src/constants/common';
import { useTrackingContext } from '@src/contexts/TrackingContext';
import { TrackingData } from '@src/contexts/TrackingContext/types';
import { Routes } from '@src/types/routes';

import { Point } from './type';

import '@omnic/widget-locations/dist/lib/style.css';

import {
  ContentPaper,
  InfoData,
  InfoName,
  InfoWrapper,
  MapContainer,
  OrderInfoContainer,
  Separator,
  Title,
} from './styled';

export default function DeliveryPoint(): JSX.Element {
  const {
    trackingData: data,
    clearPointData,
    recordLocationPoint,
    recordDeliveryAddress,
  } = useTrackingContext();
  const { palette } = useTheme();
  const { t, langData } = useTranslations();
  const navigate = useQueryNavigate();

  const trackingData = data as TrackingData;
  const isAddressSelection = trackingData.address_selection;

  const primaryColor = palette.primary.main;
  const secondaryColor = palette.grey[100];
  const latitude = Number(trackingData.point_latitude_to);
  const longitude = Number(trackingData.point_longitude_to);
  const center = useMemo(() => [latitude, longitude], [latitude, longitude]);
  const senderName = trackingData.sender_name;
  const destinationTitle = `${t('destinationMessageTitle1')} "${senderName}", ${t('destinationMessageTitle2')}`;

  function onPointClick(point: Point) {
    clearPointData();

    if ('brand' in point) {
      recordLocationPoint(point);
    } else {
      recordDeliveryAddress(point);
    }
    navigate(Routes.deliveryPointConfirmation);
  }

  return (
    <PageWrapper $withoutSidePadding $withoutBottomPadding>
      <ContentPaper>
        <Title>{t('orderDetails')}</Title>
        <OrderInfoContainer>
          <InfoWrapper>
            <InfoName>{t('orderNumber2')}:</InfoName>
            <InfoData>{trackingData.vendor_number}</InfoData>
          </InfoWrapper>
          <Separator />
          <InfoWrapper>
            <InfoName>{t('trackingNumber')}:</InfoName>
            <InfoData>{trackingData.order_number}</InfoData>
          </InfoWrapper>
        </OrderInfoContainer>
        <InfoMessage
          title={destinationTitle}
          text={t('destinationMessageText')}
        />
      </ContentPaper>
      <MapContainer id="@omnic/widget-locations">
        <Widget
          config={{
            startScreen: 'map',
            hideLogo: true,
            INN: trackingData.location_widget_inn,
            locale: langData.lang,
            palette: {
              primary: primaryColor,
              'primary-dark': secondaryColor,
            },
            map: {
              initialZoom: INITIAL_ZOOM_MAP,
              provider: 'google',
              center,
            },
            disableGeolocation: true,
            disableDelivery: !isAddressSelection,
            radius: 2,
            font: 'Roboto',
            onPointClick,
          }}
          options={{
            baseURL: `${WIDGET_BASE_URL}/type-1/public/`,
          }}
        />
      </MapContainer>
      <Footer />
    </PageWrapper>
  );
}
