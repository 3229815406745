import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryNavigate } from '@omnipkg/ui-kit-web';

import { Routes } from '@src/types/routes';

export function useHandleNavigation() {
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [finalUrl, setFinalUrl] = useState('');
  const { pathname } = useLocation();

  const navigate = useQueryNavigate();

  useEffect(() => {
    const deliveryConfirmSuccess = `${Routes.deliveryPointConfirmation}/${Routes.confirmationSuccess}`;
    const deliveryConfirmFailure = `${Routes.deliveryPointConfirmation}/${Routes.confirmationFailure}`;

    if (
      pathname.includes(deliveryConfirmSuccess) ||
      pathname.includes(deliveryConfirmFailure)
    ) {
      setIsFinalStep(true);
      setFinalUrl(pathname);
    }
  }, [pathname, navigate]);

  useEffect(() => {
    if (isFinalStep) {
      navigate(finalUrl);
    }
  }, [pathname, isFinalStep, finalUrl, navigate]);
}
