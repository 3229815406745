import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApiContextProvider } from '@omnipkg/ui-kit-web';

import App from '@src/containers/App';

import { TrackingContextProvider } from './contexts/TrackingContext';

const { ECOM_API } = process.env;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <ApiContextProvider baseURL={ECOM_API || ''}>
      <TrackingContextProvider>
        <App />
      </TrackingContextProvider>
    </ApiContextProvider>
  </BrowserRouter>,
);
