import { useRoutes as useBaseRoutes } from 'react-router-dom';

import { useTrackingContext } from '@src/contexts/TrackingContext';
import DeliveryPoint from '@src/pages/DeliveryPoint';
import DeliveryPointConfirmation from '@src/pages/DeliveryPointConfirmation';
import ConfirmationResult from '@src/pages/DeliveryPointConfirmation/components/ConfirmationResult';
import ErrorPage from '@src/pages/ErrorPage';
import Map from '@src/pages/Map';
import OrderTracker from '@src/pages/OrderTracker';
import { Routes } from '@src/types/routes';

import MainLayout from '../../MainLayout';

export function useRoutes() {
  const { flow } = useTrackingContext();

  function getElement(): React.ReactNode {
    switch (flow) {
      case 'deliveryPoint':
        return <DeliveryPoint />;
      case 'orderTracker':
        return <OrderTracker />;
      default:
        return null;
    }
  }

  return useBaseRoutes([
    {
      path: Routes.main,
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: getElement(),
        },
        {
          path: Routes.map,
          element: <Map />,
        },
        {
          path: Routes.deliveryPointConfirmation,
          children: [
            {
              index: true,
              element: <DeliveryPointConfirmation />,
            },
            {
              path: ':result',
              element: <ConfirmationResult />,
            },
          ],
        },
        {
          path: Routes.error,
          element: <ErrorPage />,
        },
      ],
    },
  ]);
}
