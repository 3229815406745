import { LINK } from './constants';

import { FooterContainer, Link } from './styled';

export default function Footer(): JSX.Element {
  return (
    <FooterContainer>
      <Link target="_self" href={LINK}>
        www.omnillama.net
      </Link>
    </FooterContainer>
  );
}
