import { Button, PageWrapper, useTranslations } from '@omnipkg/ui-kit-web';

import ActionMessage from '@src/components/ActionMessage';
import InfoMessage from '@src/components/InfoMessage';
import { useTrackingContext } from '@src/contexts/TrackingContext';
import { PointType, TrackingData } from '@src/contexts/TrackingContext/types';
import { openTracker } from '@src/utils/openTracking';

import lory from './images/lory.png';

import { ContentPaper, Lory, LoryContainer, LoryCover } from './styled';

export default function OrderTracker(): JSX.Element {
  const { trackingData } = useTrackingContext() as {
    trackingData: TrackingData;
  };

  const { t } = useTranslations();

  const pointType = trackingData.point_type_to;
  const address = `${trackingData.address_to}`;
  const point = `${trackingData.point_brand_to} ${trackingData.point_name_to}`;

  const messageTitle =
    pointType === PointType.ADDRESS ? t('address') : t('parcelLocker');
  const messageText = pointType === PointType.ADDRESS ? address : point;

  function trackOrder() {
    if (!trackingData) return;
    openTracker(trackingData);
  }

  return (
    <PageWrapper>
      <ContentPaper>
        <LoryContainer>
          <Lory src={lory} width={302} height={123} alt="lory" />
          <LoryCover />
        </LoryContainer>
        <InfoMessage
          title={t('deliveryInProgress')}
          text={t('deliveryPointCantBeChanged')}
        />
        <ActionMessage title={messageTitle} text={messageText} />
        <ActionMessage
          title={t('trackingNumber')}
          text={trackingData.order_number}
          action="copy"
        />
      </ContentPaper>
      <Button
        label={t('trackYourOrder')}
        variant="contained"
        onClick={trackOrder}
      />
    </PageWrapper>
  );
}
