import ReactGA from 'react-ga4';
import { WithUiContexts } from '@omnipkg/ui-kit-web';

import { useTrackingContext } from '@src/contexts/TrackingContext';

import { useRoutes } from './hooks/useRoutes';

ReactGA.initialize(process.env.GA_KEY as string);

export default function App() {
  const routes = useRoutes();

  const { siteSettings } = useTrackingContext();

  const projectLangs = siteSettings?.country_settings.languages;
  const defaultLang = siteSettings?.country_settings.default_language;
  const theme = siteSettings?.network_settings.web_page_design;

  return (
    <WithUiContexts
      translationSettings={{
        projectLangs,
        defaultLang,
      }}
      themeSettings={{
        theme,
        isPrimary: true,
      }}
    >
      {routes}
    </WithUiContexts>
  );
}
