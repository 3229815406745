import { useLayoutEffect } from 'react';
import {
  Button,
  ButtonsWrapper,
  ContentWrapper,
  PageWrapper,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import ActionMessage from '@src/components/ActionMessage';
import { useTrackingContext } from '@src/contexts/TrackingContext';
import { Routes } from '@src/types/routes';

import { useConfirmPointHandler } from './hooks/useConfirmPointHandler';

import { Information, PointInfoWrapper, Title } from './styled';

export default function DeliveryPointConfirmation(): JSX.Element {
  const { locationPoint, deliveryAddress, trackingUid, trackingData, flow } =
    useTrackingContext();

  const deliveryData = locationPoint || deliveryAddress;
  const locationBrand = locationPoint?.brand;
  const locationName = locationPoint?.name;
  const point =
    locationBrand && locationName ? `${locationBrand} ${locationName}` : '';
  const address =
    locationPoint?.location.full_address ||
    deliveryAddress?.address?.address ||
    '';

  const { t } = useTranslations();
  const navigate = useQueryNavigate();

  const { isPending, confirmPoint } = useConfirmPointHandler();

  function onConfirm() {
    if (!trackingUid || !trackingData) return;

    if (!!locationPoint) {
      confirmPoint({
        trackingUid,
        orderUid: trackingData.uid,
        brand: locationPoint.brand,
        code: locationPoint.code,
      });
      return;
    }

    if (!!deliveryAddress) {
      confirmPoint({
        trackingUid,
        orderUid: trackingData.uid,
        address: deliveryAddress.address,
      });
    }
  }

  useLayoutEffect(() => {
    if (!deliveryData || flow !== 'deliveryPoint') {
      navigate(Routes.main);
    }
  }, [deliveryData, flow, navigate]);

  return (
    <PageWrapper>
      <ContentWrapper>
        <Title>{t('doYouConfirmDeliveryPoint')}</Title>
        <Information>{t('confirmationAttention')}</Information>
        <PointInfoWrapper>
          {point && <ActionMessage title={t('parcelLocker')} text={point} />}
          <ActionMessage title={t('address')} text={address} />
        </PointInfoWrapper>
      </ContentWrapper>
      <ButtonsWrapper>
        <Button
          isLoading={isPending}
          label={t('back')}
          variant="outlined"
          onClick={() => navigate(-1)}
        />
        <Button
          disabled={isPending}
          label={t('confirm')}
          variant="contained"
          onClick={onConfirm}
        />
      </ButtonsWrapper>
    </PageWrapper>
  );
}
