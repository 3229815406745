import { useState } from 'react';
import { useQueryNavigate } from '@omnipkg/ui-kit-web';

import { Routes } from '@src/types/routes';

import { ConfirmPointArgs, useConfirmPoint } from '../utils/confirmPoint';

interface HookReturns {
  isPending: boolean;
  confirmPoint: (args: ConfirmPointArgs) => void;
}

export function useConfirmPointHandler(): HookReturns {
  const [isPending, setIsPending] = useState(false);

  const navigate = useQueryNavigate();

  const confirmPointRequest = useConfirmPoint();

  function confirmPoint(args: ConfirmPointArgs) {
    setIsPending(true);
    confirmPointRequest(args)
      .then(() => {
        navigate(Routes.confirmationSuccess);
      })
      .catch(() => {
        navigate(Routes.confirmationFailure);
      })
      .finally(() => {
        setIsPending(false);
      });
  }

  return {
    isPending,
    confirmPoint,
  };
}
