import { useLocation } from 'react-router-dom';
import {
  ErrorType,
  Message,
  PageWrapper,
  useTranslations,
} from '@omnipkg/ui-kit-web';

export default function ErrorPage(): JSX.Element {
  const { state } = useLocation();
  const errorType = state?.errorType as ErrorType.orderDoesNotExist | undefined;
  const { t } = useTranslations();

  const title = errorType ? t('invalidUrl') : t('somethingWentWrong');
  const subtitle = errorType ? t('checkYourLink') : '';

  return (
    <PageWrapper $verticalAlign="center">
      <Message
        iconConfig={{ name: 'error' }}
        title={title}
        subtitle={subtitle}
      />
    </PageWrapper>
  );
}
