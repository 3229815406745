import { css } from 'styled-components';

export const H2 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(24)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const H3 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(20)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const H4 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(18)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt1Bold = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(16)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;

export const Txt2 = css`
  font-size: ${({ theme }): string => theme.typography.fontSize(14)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const Txt2Bold = css`
  ${Txt2}
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
`;
