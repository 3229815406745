import { ContentWrapper } from '@omnipkg/ui-kit-web';
import styled from 'styled-components';

import { H3, H4, Txt2Bold } from '@src/theme/common';

export const ContentPaper = styled(ContentWrapper)`
  padding: 0 ${({ theme }): string => theme.spacing(2.5)};
`;

export const Title = styled.h1`
  ${H4};
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
`;

export const OrderInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }): string => theme.spacing(2)};
  border: 1px solid ${({ theme }): string => theme.palette.graphite[10]};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(1.5)} ${spacing(2)}`};
  margin-bottom: ${({ theme }): string => theme.spacing()};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing()};
  align-items: center;
  flex-grow: 1;
`;

export const InfoName = styled.h2`
  ${Txt2Bold};
`;

export const InfoData = styled.span`
  ${H3};
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }): string => theme.palette.graphite[10]};
`;

export const MapContainer = styled.div`
  height: 77vh;
`;
