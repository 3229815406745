import styled from 'styled-components';

import { H2, Txt2 } from '@src/theme/common';

export const Title = styled.h1`
  ${H2};
  text-align: center;
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const Information = styled.h3`
  ${Txt2};
  text-align: center;
  margin-bottom: ${({ theme }): string => theme.spacing(2.5)};
`;

export const PointInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(2)};
`;
