import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }): string => theme.spacing(0.75)};
`;

export const Link = styled.a`
  font-size: ${({ theme }): string => theme.typography.fontSize(12)};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-decoration: none;
`;
